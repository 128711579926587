<template>
  <div class="glossary">
    <Glossary></Glossary>
  </div>
</template>

<script>
import Glossary from "@/components/Glossary-Content.vue";
export default {
  name: "Home",

  components: {
    Glossary: Glossary
  },
  mounted() {},
  methods: {}
};
</script>
